import React from "react";
import { styles } from "../../styles/styles";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { MdArrowForward } from "react-icons/md";

export const Header = ({ theme }) => {

const yellow = theme.palette.primary.secondary

  return (
    <Box sx={styles.headerBackground}>
      <Container maxWidth={"xl"} sx={styles.section}>
        <Grid container pt={10} sx={styles.headerSection}>
          <Grid item sm={6}  sx={styles.responsiveSection}>
            <Box sx={{width:'100%', mb:1}}>
              <Box sx={{display:'flex',flexDirection:'row'}}>

              <Typography variant="body1">SÉ PARTE DE LA REVOLUCIÓN </Typography>
              <Typography variant="body1" color={yellow} ml={0.5}>!</Typography>

              </Box>
              <Typography variant="h3">
                Aprende a invertir en{" "}
                <Typography
                  variant="h3"
                  color={yellow}
                >
                  Criptomonedas
                </Typography>
              </Typography>
            </Box>

            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mt:1,
              }}
            >
              <Box mr={2} sx={{ color:yellow}}>
                <MdArrowForward size={45} />
              </Box>
              <Typography variant="body1">
                El ecosistema Revolution te permite aprender a nivel profesional
                sobre los mercados financieros, mientras generas ganancias,
                incluso desde el primer día.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
