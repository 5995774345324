import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { FaRocket } from "react-icons/fa";

export const RevoButton = ({
  buttonText,
  backgroundColor,
  color,
  outlined,
  borderColor,
  width,
  shadow,
  height,
  fontSize,
  weight,
  icon,
  onClick,
  disabled,
}) => {
  const theme = useTheme();
  const buttonBaseStyles = {
    display: "flex",
    flexDirection: "row",
    width: width,
    backgroundColor: backgroundColor ?? null,
    borderRadius: "2em",
    pr: 2,
    pl: 2,
    boxShadow: `-2px -2px 25px 0px ${shadow}35`,
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      fontSize: "12px",
    },
  };

  const buttonOutlinedStyles = {
    ...buttonBaseStyles,
    borderColor: borderColor,
    height: height,
    alignContent: "center",
    justifyContent: "center",
    mr: 1,
  };

  return (
    <Button
      variant={outlined ? "outlined" : "contained"}
      sx={{
        ...(outlined ? buttonOutlinedStyles : buttonBaseStyles),
        "&:hover": {
          backgroundColor: outlined ? backgroundColor : "#59d8fb",
          borderColor: outlined ? borderColor : null,
          cursor: "pointer",
        },
        "&:active": {
          backgroundColor: outlined ? backgroundColor : "#666666",
          borderColor: outlined ? borderColor : null,
        },
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <Box>
        {icon}
        <Typography
          variant="p"
          fontSize={fontSize}
          sx={{
            color: color,
            fontWeight: `${weight} !important`,
            ml: icon ? 1 : 0,
          }}
        >
          {buttonText}
        </Typography>
      </Box>
    </Button>
  );
};
