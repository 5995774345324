import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import { PrivateRoute } from "./PrivateRoute";

import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";
import { Home } from "../pages/Home";
import { AboutUs } from "../pages/AboutUs";
import { Academy } from "../pages/Academy";
import { Benefits } from "../pages/Benefits";
import { Login } from "../pages/auth/Login";
import { Terms } from "../pages/Terms";
import { Register } from "../pages/auth/Register";
import DeleteAccount from "../pages/auth/DeleteAccount";

function AppRoutes() {
  const dispatch = useDispatch();
  const history = useLocation();
  //   const { loggedIn, isLoadingAuth } = useSelector((state) => state.auth);
  const lastUrl = localStorage.getItem("lastUrl") || "/home";
  //   useEffect(() => {
  //     dispatch(setIsLoadingAuth(true));
  //     auth.onAuthStateChanged(async (user) => {
  //       if (user) {
  //         const metadata = await user.getIdTokenResult();
  //         dispatch(signInWithEmail(user.toJSON(), metadata.claims));
  //         sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
  //         dispatch(setIsLoadingAuth(false));
  //       } else {
  //         console.log("no hay");
  //         sessionStorage.removeItem("user");
  //         dispatch(logoutUser());
  //         dispatch(setIsLoadingAuth(false));
  //       }
  //     });
  //   }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("lastUrl", history.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history]);

  //   if (isLoadingAuth) {
  //     return (
  //       <div id="login">
  //         <div className="login-wrapper ">
  //           <div className="align">
  //             <Typography component="h1" variant="h5">
  //               Cargando ...
  //             </Typography>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="home" />} />
        <Route index path="/home" element={<Home />} />
        <Route path="/sobre-nosotros" element={<AboutUs />} />
        <Route path="/academia" element={<Academy />} />
        <Route path="/beneficios" element={<Benefits />} />
        <Route path="/terminos" element={<Terms />} />
        <Route path="/registro" element={<Register />} />
        <Route path="/register" element={<Navigate to="/registro" />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        {/* {!loggedIn ? (
          <Route Route path="/auth">
            <Route path="" element={<Navigate replace to="login" />} />
            <Route path="login" element={<Login />} />
          </Route>
        ) : null} */}
      </Routes>
    </>
  );
}

export default AppRoutes;
