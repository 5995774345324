export const registerForm = [
  {
    question: "¿PARA QUE ESTAS COMPLETANDO ESTE FORMULARIO?",

    answers: [
      {
        id: 1,
        answer: "Es mi primer ingreso a Revolution",
      },
      {
        id: 2,
        answer:
          " Ya tengo acceso a la app, solo quiero acceso al curso avanzado",
      },
    ],
  },
];
