import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { navbarStyles } from "../styles/navbarStyles";
import { blue, dark, yellow } from "../styles/styles";
import logo from "../assets/img/Logo.png";
import logo2 from "../assets/img/logoWhite.png";
import { useLocation, useNavigate } from "react-router-dom";
import { RevoButton } from "../components/general/RevoButton";
import MenuIcon from "@mui/icons-material/Menu";

export const RevoNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const navigateTo = (link) => {
    navigate(link);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "5491168502040";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const renderNavbarLinks = () => (
    <Box sx={navbarStyles.navbarLinks}>
      <Typography
        variant="body1"
        component="a"
        href="/home"
        sx={{
          ...navbarStyles.link,
          color: currentPath === "/home" ? blue : "white",
          userSelect: "none",
        }}
      >
        Inicio
      </Typography>
      <Typography
        variant="body1"
        sx={{
          ml: 2,
          color: currentPath === "/about" ? blue : "white",
          userSelect: "none",
        }}
      >
        Sobre Nosotros
      </Typography>
      <Typography
        variant="body1"
        sx={{
          ml: 2,
          color: currentPath === "/academia" ? blue : "white",
          userSelect: "none",
        }}
      >
        Academia
      </Typography>
      <Typography
        variant="body1"
        sx={{
          ml: 2,
          color: currentPath === "/beneficios" ? blue : "white",
          userSelect: "none",
        }}
      >
        Beneficios
      </Typography>
    </Box>
  );

  return (
    <AppBar position="absolute" sx={navbarStyles.appBar}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {!isMobile && (
          <Box sx={navbarStyles.logoContainer}>
            <img src={logo} style={navbarStyles.logo} alt="Logo" />
          </Box>
        )}
        {isMobile ? (
          // For mobile view, show a responsive menu button
          <>
            <Button
              sx={{ display: { sm: "block", md: "none" } }}
              onClick={handleDrawerOpen}
            >
              <Box sx={navbarStyles.logoContainer2}>
                <img src={logo2} style={navbarStyles.logo2} alt="Logo" />
              </Box>
              {/* <MenuIcon sx={{ color: "white" }} /> */}
            </Button>

            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={handleDrawerClose}
              PaperProps={{
                sx: {
                  backgroundImage: `url(${require("../assets/img/headerBackground.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: dark,
                  color: "white",
                  width: "225px",
                },
              }}
            >
              <List>
                <ListItemButton component="a" href="/home" disabled>
                  Home
                </ListItemButton>
                <ListItemButton component="a" href="/about" disabled>
                  Sobre Nosotros
                </ListItemButton>
                <ListItemButton component="a" href="/academia" disabled>
                  Academia
                </ListItemButton>
                <ListItemButton component="a" href="/beneficios" disabled>
                  Beneficios
                </ListItemButton>
              </List>
            </Drawer>
          </>
        ) : (
          // For larger screens (e.g., desktop), show the regular navigation links
          renderNavbarLinks()
        )}
        <Box sx={navbarStyles.navbarLinks}>
          <RevoButton
            outlined
            buttonText={"Login"}
            borderColor={yellow}
            width={"12em"}
            color={"white"}
            onClick={handleWhatsAppClick}
          />
          <RevoButton
            buttonText={"Iniciar Ahora"}
            backgroundColor={blue}
            color={"white"}
            shadow={blue}
            onClick={() => navigateTo("/registro")}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
