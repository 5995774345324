import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { lighterDark, styles, yellow } from "../../styles/styles";
import cardImage from "../../assets/img/card.png"; // Import the image here
import { dark } from "@mui/material/styles/createPalette";

export const InfoCard = ({ color, bigColor, item }) => {
  const [hoveredId, setHoveredId] = useState(item.id === '1' ? item.id : null);

  useEffect(() => {
    if (hoveredId === null) {
      setHoveredId(item.id === '1' && item.id)
    }
  }, [hoveredId])

  const handleHover = (id) => {
    setHoveredId(id);
  };

  const shouldApplyMargin = hoveredId === item.id && item.id !== null;
  const shouldApplyOpacity = hoveredId === item.id ;

  return (
    <Box
      sx={{
        ...styles.infoCard,
        opacity: shouldApplyOpacity ? '100%' : '50%',
        mt: shouldApplyMargin ? '-2rem' : 0,
        backgroundImage: item.id === '1' ? `url(${cardImage})` : `linear-gradient(to right bottom, ${dark}, ${lighterDark})` ,
        backgroundSize:'cover',
        zIndex:item.id === '1'? 1000 : 100,
     position:'relative',
        textShadow: `-2px -2px 25px 0px ${yellow}90`,
      }}
      // onMouseEnter={() => handleHover(item.id)}
      // onMouseLeave={() => handleHover(null)}
    >
      <Typography variant="h4" color={bigColor} textAlign={'center'} sx={{   color:item.id === '1' ? yellow: 'white',}}>
        {item.bigText}
      </Typography>
      <Typography variant="body2" color={color} textAlign={'center'} fontSize={10}>
        {item.text}
      </Typography>
    </Box>
  );
};
