import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../styles/styles";

export const Terms = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: "#121212" }}>
      <Box sx={styles.contactHeader}></Box>
      <Container maxWidth="md">
        <Box
          sx={{
            width: "100%",

            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          // mt={10}
          mb={10}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <div className="titleIcon">
                <Typography mr={"auto"} ml={2} sx={styles.boldTextLight}>
                  TÉRMINOS Y CONDICIONES DE USO
                </Typography>
              </div>
            </Box>
          </Grid>

          <ol className="termsList">
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                GENERAL
              </Typography>
            </Box>
            <li>
              Los siguientes Términos y Condiciones Generales, en adelante,
              “T&C” se aplicarán para la navegación y uso de la aplicación móvil
              REVOLUTION, en adelante denominada App o RevolutionApp. Los
              presentes T&C regulan la relación legal con los servicios
              ofrecidos en la misma. .
            </li>
            <li>
              El solo ingreso a la App por parte de un usuario implica la
              aceptación completa de los Términos y Condiciones de Uso que se
              detallan a continuación.
            </li>
            <li>
              USUARIO: Será la persona que ingrese y navegue la App para obtener
              información, capacitación, formación en trading y mercados
              financieros.
            </li>
            <li>
              PRESTADOR: Será REVOLUTION, proveedor de servicio educativo de
              trading y mercados financieros.{" "}
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                OBJETO DE LA APLICACIÓN:
              </Typography>
            </Box>

            <li>
              La App tiene como objetivo proporcionar al USUARIO cursos,
              capacitaciones e información general con fines educativos en
              materia de trading y mercados financieros.
            </li>
            <li>
              RevolutionApp, es una aplicación que no brinda servicios
              financieros por lo que el contenido en la misma es de carácter
              educativo
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                ACEPTACIÓN DE LOS T&C:
              </Typography>
            </Box>

            <li>
              Todo el contenido legal expresado en los presentes T&C y sus
              futuras modificaciones son vinculantes para cualquier persona que
              navegue la App. Las modificaciones serán efectuadas a través del
              sitio web revolutionarg.com. Por lo que el uso y navegación
              implica el conocimiento y la aceptación de lo expresado en el
              presente. Si usted no está de acuerdo con los T&C, deberá cancelar
              su navegación, estén o no registrados.
            </li>
            <li>
              Esta página deberá ser visitada de forma regular a fin de conocer
              los cambios que se produzcan, sin embargo, notificaremos o
              intentaremos notificar los mismos.
            </li>
            <li>
              Es necesario contar con la edad y la capacidad legal para aceptar
              los T&C. Debe tener al menos 18 años (o la edad mínima equivalente
              en su país) encontrándose debidamente supervisados por sus padres,
              tutores y/o personas mayores de edad que tengan su cuidado.
              Quedará bajo exclusiva responsabilidad de las personas a cargo de
              menores, eximiendo de responsabilidad al PRESTADOR por todo uso
              contrario a estos T&C, a la Política de Privacidad, y a la
              legislación de cada país donde se utiliza el Servicio.
            </li>
            <li>
              Al aceptar los presentes T&C, se considera que el usuario ha
              comprendido, aceptado y está obligado a la política de privacidad
              que es una parte integral de los T&C.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                USO DE LA APLICACIÓN:
              </Typography>
            </Box>
            <li>
              El uso de la App es exclusiva responsabilidad del usuario, quien
              en todo caso deberá servirse del mismo acorde a las
              funcionalidades permitidas y a los usos autorizados en los
              presentes T&C, por lo que el usuario se obliga a utilizar de modo
              tal que no atenten contra las normas de uso y convivencia en
              Internet, y la legislación vigente en el país en que el usuario se
              encuentre al usarlos, las buenas costumbres, la dignidad de la
              persona y los derechos de terceros.
            </li>
            <li>
              Los usuarios se comprometen a utilizar los servicios o contenidos
              exclusivamente para su uso personal, y a no realizar ni directa ni
              indirectamente una explotación comercial de los mismos. Los
              usuarios no tienen el derecho de colocar hipervínculos de y hacia
              la Appb, ni el derecho de colocar o utilizar los contenidos del
              mismo en sitios o páginas propias o de terceros sin autorización
              previa y por escrito del PRESTADOR. Asimismo, los usuarios no
              tendrán el derecho de limitar o impedir a cualquier otro usuario
              el uso de la App.
            </li>
            <li>
              El PRESTADOR velará por el cumplimiento de las anteriores
              condiciones como por la debida utilización de los contenidos
              presentados en su App, ejercitando todas las acciones civiles y
              penales que le correspondan en el caso de infracción o
              incumplimiento de estos derechos por parte del Usuario.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                CONCEPTOS BÁSICOS DEL FUNCIONAMIENTO:
              </Typography>
            </Box>
            <li>
              Los USUARIOS se comprometen a que toda la información volcada
              sobre sus datos será veraz y de lo contrario serán responsables
              por el accionar dañoso de la información que suministren.
            </li>
            <li>
              En la App pueden existir enlaces que permitan al usuario acceder a
              sitios de terceros. De existir dichos enlaces, los mismos no
              podrán interpretarse como asociación de ningún tipo entre
              RevolutionApp y dichos terceros. El usuario declara conocer que el
              acceso a otros sitios implicará someterse a los términos y
              condiciones de los mismos, que podrán ser distintos al presente.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                DESCARGO DE RESPONSABILIDAD:
              </Typography>
            </Box>
            <li>
              Los usuarios aceptan y conocen que el contenido proporcionado en
              nuestros App son solo para fines educativos. No constituyen una
              garantía de éxito en el trading ni deben interpretarse como una
              invitación a realizar transacciones específicas, quedando bajo su
              absoluta responsabilidad el capital que pudiere utilizar.
            </li>
            <li>
              Asimismo, el usuario que lleve a cabo transacciones que impliquen
              la captación de capitales de terceros en el ámbito de trading o
              mercados financieros en nombre o representación del PRESTADOR,
              será considerada una violación de nuestros términos y condiciones,
              y nos reservamos el derecho de tomar medidas legales y/o suspender
              tu acceso a la aplicación en caso de detectar tales actividades.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                PROCESO DE RESOLUCIÓN DE DISPUTAS
              </Typography>
            </Box>
            <li>
              Los USUARIOS que tuviesen algún conflicto en cualquier proceso
              dentro de la App podrán iniciar un proceso de resolución de
              disputas interno comunicándose a través del correo electrónico:
              legales@revolutioncorporate.com
            </li>
            <li>
              El PRESTADOR observará las mejores prácticas para resolver el
              mismo y emitirá una decisión fundada.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                INDEMNIDAD
              </Typography>
            </Box>
            <li>
              Los usuarios aceptan mantener indemne a la App de cualquier tipo
              de reclamación, responsabilidad, pérdida, gastos, honorarios
              técnicos y legales, solicitudes y remisión de información por
              incumplimientos en estos términos y condiciones.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                PROPIEDAD INTELECTUAL
              </Typography>
            </Box>
            <li>
              Los usuarios aceptan que todos los derechos de propiedad
              intelectual son propiedad del PRESTADOR, así como también las
              marcas, patentes, derechos de autor, modelos y diseños
              industriales, trade dress, apariencia, logos, dibujos, iconos.
            </li>
            <li>
              La reproducción, almacenaje y difusión o cualquier otro uso de los
              contenidos en el App que tenga finalidad pública o comercial por
              un tercero queda expresamente prohibida, salvo que contenga el
              previo consentimiento por escrito del PRESTADOR.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                USO NO PERMITIDO:
              </Typography>
            </Box>
            <li>
              El usuario acepta utilizar la App de forma legítima, teniendo
              prohibido utilizar en relación a:
            </li>
            <ol className="termsList">
              <li>- Cualquier actividad que sea violatoria a normativas.</li>
              <li>
                - Cualquier contenido o producto que infringe derechos de
                propiedad intelectual e industrial.
              </li>
              <li>
                - Cualquier uso indebido de la aplicación con el propósito de
                captar capitales de terceros.
              </li>
              <li>
                - Contenido violento o que incite al odio, intimidación, abuso
                de cualquier tipo contra cualquier persona, grupo o institución.
              </li>
              <li>
                - Material de abuso sexual infantil o cualquier contenido que
                explote o dañe.
              </li>
              <li>
                - Cualquier otra actividad que el PRESTADOR pueda considerar
                inaceptable.
              </li>
            </ol>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                CONFLICTOS - LIMITACIÓN DE RESPONSABILIDAD - CONTACTO
              </Typography>
            </Box>
            <li>
              El uso de la App se proporciona según se encuentra al momento de
              uso con todas sus funcionalidades y especificaciones.{" "}
            </li>
            <li>
              Si tiene dudas, sugerencias o reclamos puede hacerlos a la
              dirección de correo electrónico legales@revolutioncorporate.com.{" "}
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                LEGISLACIONES Y JURISDICCIONES AUTORIZADAS
              </Typography>
            </Box>
            <li>
              Todas las controversias o litigios derivados del uso del servicio
              serán sometidas a los tribunales ordinarios con asiento en la
              Ciudad de Buenos Aires, República Argentina. Estos Términos y
              Condiciones se regirán por las leyes de la República Argentina. Al
              contratar nuestros servicios usted declara conocer y aceptar estas
              políticas de uso términos y condiciones"
            </li>
            <li>
              Si tiene dudas, sugerencias o reclamos puede hacerlos a la
              dirección de correo electrónico legales@revolutioncorporate.com.{" "}
            </li>
            <Box mt={8} mb={2}>
              <Typography mr={"auto"} variant="body2">
                VERSIÓN DE LOS TÉRMINOS Y CONDICIONES AGOSTO 2023
              </Typography>
            </Box>
          </ol>
        </Box>

        <Box
          sx={{
            width: "100%",

            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          mb={20}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <div className="titleIcon">
                <Typography mr={"auto"} ml={2} sx={styles.boldTextLight}>
                  POLÍTICAS DE PRIVACIDAD & TRATAMIENTO DE DATOS PERSONALES
                </Typography>
              </div>
            </Box>
          </Grid>

          <ol className="termsList">
            <Box mt={4} mb={4}>
              <li>
                Al registrarse y utilizar la aplicación REVOLUTION usted conoce
                y acepta que el tratamiento de sus datos personales se realice
                de acuerdo con lo informado en el presente documento.
              </li>
            </Box>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                I. PROTECCIÓN DE PRIVACIDAD
              </Typography>
            </Box>
            <li>
              REVOLUTION solicitará ciertos datos a cada usuario y serán estos
              datos los que permitan y favorezcan tanto el funcionamiento como
              los procesos de seguridad que se desarrollen en base a estos.
              REVOLUTION considera que los datos que declare el USUARIO deberán
              ser ciertos y reales para permitirle el acceso a la aplicación.
              Cualquier falsedad, error voluntario u omisión voluntaria será
              motivo para la cancelación del acceso a la cuenta.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                II. PROCESAMIENTO DE COOKIES
              </Typography>
            </Box>

            <li>
              REVOLUTION se reserva el derecho a utilizar "cookies". Los
              contenidos de estas cookies serán archivos de bajo tamaño que se
              remitirán al dispositivo de quien accede para garantizar el
              correcto funcionamiento y visualización de la misma de la mejor
              forma posible por parte del USUARIO. Las cookies serán utilizadas
              temporalmente y desaparecerán al terminar la sesión del usuario.
              En ningún caso se utilizarán las cookies para recoger información
              de carácter personal o datos de acceso de los usuarios.
            </li>
            <li>Específicamente va a utilizar:</li>
            <ol className="termsList">
              <li>● Datos de navegación</li>
              <li>
                ● Vías de acceso a la aplicación (redes sociales, ads, directos,
                etc)
              </li>
              <li>● Geoubicación del acceso</li>
              <li>● Dispositivo utilizado</li>
              <li>● Login y acceso de la aplicación</li>
              <li>● Email de registro</li>
            </ol>
            <li>
              Si el USUARIO cuenta con los medios técnicos podrá realizar los
              ajustes necesarios en su navegador a fin de deshabilitar el uso de
              las cookies. Si la deshabilitación de cookies generará
              complicaciones en el uso, el USUARIO deberá abstenerse de hacerlo
              y restablecer la configuración. REVOLUTION no será responsable por
              la deshabilitación de las cookies.
            </li>
            <li>
              En el caso de uso de cookies de terceros, REVOLUTION avisará a los
              navegantes o USUARIO con el fin de establecer relaciones
              comerciales con terceros. Todas estas soluciones son ampliamente
              divulgadas y conocidas por un gran número de USUARIOS por lo que
              su uso se encuentra previamente descripto en muchas páginas de
              internet.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                III. TRATAMIENTO DE DATOS PERSONALES
              </Typography>
            </Box>

            <li>
              Existe determinada información que REVOLUTION maneja, considerada
              información personal, dado que esta información identifica a los
              usuarios. Usamos perfiles de usuario e información anónima con el
              fin de asegurar nuestra PDP.
            </li>
            <li>
              El análisis de los datos que no identifiquen serán tratados a
              través de herramientas informáticas (software) y que nos brindará
              y ayudará a mejorar la experiencia del usuario. Esos datos
              formarán parte de la base de datos de clientes y REVOLUTION será
              su único responsable. Cualquier uso de la información será
              referido a los clientes y se asegura su tratamiento confidencial y
              protegiendo la privacidad de estos en virtud de las leyes de
              protección de datos personales.
            </li>
            <li>
              Para el cumplimiento de nuestras actividades deberemos usar la
              información personal para:
            </li>

            <ol className="termsList">
              <li>a. Brindar servicios</li>
              <li>b. Administrar la cuenta</li>
              <li>c. Resolver conflictos</li>
              <li>d. Ofrecer, ofertar</li>
              <li>e. Proteger nuestra red</li>
              <li>f. Verificar la identidad</li>
              <li>g. Cumplir nuestras obligaciones legales</li>
              <li>h. Realizar análisis estadísticos y de servicios</li>
              <li>i. Mejorar el uso de recursos</li>
            </ol>
            <li>
              Sobre el proceso de manejo de información de carácter informativo
              para el cumplimiento de los términos y condiciones y para el envío
              de información automatizada, cada USUARIO deberá aceptar el envío
              de información y podrá darse de alta o baja en cualquier momento.
              La aplicación REVOLUTION puede contener publicidad y enlaces a
              sitios externos por lo que una vez fuera del mismo, la
              responsabilidad de REVOLUTION deja de ser exigible.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                IV. DERECHO DE ACCESO Y RECTIFICACIÓN
              </Typography>
            </Box>
            <li>
              Cualquier USUARIO podrá solicitar el derecho de acceso solamente
              remitiendo un mail a legales@revolutioncorporate.com. El periodo
              de acceso será dentro de los diez (10) días corridos. Asimismo,
              podrán ejercer el derecho de rectificación o anulación de los
              datos de la persona previo análisis de factibilidad técnicas.
            </li>
            <li>
              REVOLUTION manifiesta que no compartirá la información con
              terceras personas sino para el cumplimiento de sus propios fines.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                V. RESPONSABLE DEL MANEJO DE DATOS
              </Typography>
            </Box>
            <li>
              REVOLUTION será el responsable del manejo y reclamos sobre los
              datos en virtud de las leyes de protección de datos personales.
              Por lo tanto, se designa a:
            </li>
            <li>
              A. Servicio de Atención Clientes: legales@revolutioncorporate.com
            </li>
          </ol>
        </Box>
      </Container>
    </Box>
  );
};
