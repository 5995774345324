import React from "react";
import { Header } from "../components/home/Header";
import { Box, useTheme } from "@mui/material";
import { Content } from "../components/home/Content";
export const Home = () => {
  const theme = useTheme();



  return (
    <Box>
      <Header theme={theme} />
      <Content theme={theme} />
    </Box>
  );
};
