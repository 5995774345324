import React, { useState } from "react";
import { blue, dark, styles, yellow } from "../../styles/styles";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { RevoButton } from "../general/RevoButton";
import { MdArrowForward } from "react-icons/md";
import coin from "../../assets/img/coin.png";
import astronaut from "../../assets/img/astronaut.png";
import map from "../../assets/img/map.png";
import { InfoCard } from "../cards/InfoCard";
import { iconCardData, iconCardData2, infoCardData } from "../utils/CardData";
import { IconCard } from "../cards/IconCard";
import { IconCardTwo } from "../cards/IconCardTwo";
import { LandingCarousel } from "../carousels/LandingCarousel";
import { FaRocket } from "react-icons/fa";
import DarkDivider from "../DarkDivider";

export const Content = ({ theme }) => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "5491168502040";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const renderInfoCards = () => {
    return (
      <Grid container item spacing={0} sx={styles.infoCardContainer}>
        {infoCardData.map((card, index) => (
          <Grid key={index} item md={4} xs={4} mt={4} sx={{}}>
            <InfoCard item={card} index={index} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderIconCards = () => {
    return (
      <Grid
        container
        item
        spacing={{xs: 2}}
        md={12}
        sx={{
          position: "relative",
          width: "85%",
          margin: "0 auto",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        {iconCardData.map((card, index) => (
          <Grid
            key={index}
            item
            md={4}
            mt={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: " auto",
              marginRight: " auto",
            }}
          >
            <IconCard item={card} index={index} color={"white"} />
          </Grid>
        ))}
      </Grid>
    );
  };
  const renderIconCards2 = () => {
    return (
      <Grid
        container
        item
        spacing={0}
        md={12}
        sx={{
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "85%",
          margin: "0 auto",
        }}
      >
        {iconCardData2.map((card, index) => (
          <Box
            key={index}
            mt={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconCardTwo item={card} index={index} color={"white"} />
          </Box>
        ))}
      </Grid>
    );
  };

  return (
    <Box>
      {/* SECTION ONE */}
      {/* <DarkDivider /> */}
      <Box sx={styles.joinBackground}>
        <Container maxWidth={"xl"} sx={styles.section}>
          <Grid container pt={10} spacing={3} alignItems={"center"}>
            <Grid
              item
              md={6}
              sm={12}
              order={{ md: 1, sm: 2, xs: 2 }}
              sx={styles.responsiveSection}
            >
              <Box sx={styles.mapImgContainer}>
                <img src={map} style={styles.mapImg} />
              </Box>
            </Grid>
            <Grid item md={6} sm={12} p={0} order={{ md: 2, sm: 1, xs: 1 }}>
              <Box mb={2} sx={{ flexDirection: "row" }}>
                <Typography
                  variant="h4"
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  Únete a la academia
                  <Typography variant="h4" color={blue} sx={{ ml: 1 }}>
                    #1
                  </Typography>
                </Typography>
                <Typography variant="h4">
                  en trading de Latinoamérica
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ width: "85%" }}>
                Deja de quemar cuentas y perder dinero. Aprende con nosotros y
                conviértete en un Trader experto.
              </Typography>
              {renderInfoCards()}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* SECTION TWO */}

      <Box sx={styles.knowMoreBackground}>
        <Container maxWidth={"xl"}>
          <Grid container sx={styles.section}>
            <Grid item sm={10} md={5}>
              <Typography variant="h4">Conoce más</Typography>
              <Typography
                variant="h4"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                acerca de{" "}
                <Typography variant="h4" color={yellow} ml={1}>
                  Revolution
                </Typography>
              </Typography>
              <Typography variant="body1" mt={2}>
                Somos una comunidad digital de traders expertos con más de 4
                años operando de manera efectiva y rentable en los mercados
                financieros.
              </Typography>
              <Typography variant="body1" mt={2}>
                Luego de alcanzar el éxito nos dimos cuenta que no era
                suficiente y decidimos crear una hoja de ruta clara para que tu
                puedas lograrlo también.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mt: 2,
                  [theme.breakpoints.down("sm")]: {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "2em auto",
                  },
                }}
              >
                <RevoButton
                  buttonText={"Quiero ser parte"}
                  color={"black"}
                  backgroundColor={yellow}
                  shadow={yellow}
                />

                <Typography
                  sx={{
                    ml: 2,
                    textDecoration: "underline",
                    [theme.breakpoints.down("sm")]: {
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "2em auto",
                    },
                  }}
                >
                  Tengo una pregunta?
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={10} md={7} sx={styles.center} mt={{ xs: 2 }}>
              <Box sx={styles.coinImgContainer}>
                <img src={coin} style={styles.coinImg} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* SECTION FOUR */}

      <Box sx={styles.bePartBackground}>
        <Container maxWidth={"xl"}>
          <Grid container sx={styles.section}>
            <Grid item sm={6} order={{ md: 1, sm: 2, xs: 2 }}>
              <Box sx={styles.astronautImgContainer}>
                <img src={astronaut} style={styles.astronautImg} />
              </Box>
            </Grid>
            <Grid item md={5} order={{ md: 2, sm: 1, xs: 1 }}>
              <Typography variant="h4">Sé parte de la</Typography>
              <Typography
                variant="h3"
                fontSize={48}
                color={yellow}
                sx={styles.textShadow}
              >
                REVOLUCIÓN
              </Typography>

              <Typography variant="body1" mt={{ xs: 2 }}>
                No queremos alardear, pero, no desarrollamos simples cursos de
                trading “como los cientos que verás en internet”, sino que hemos
                desarrollado el sistema educativo en trading de criptomonedas
                más completo de la galaxia.
              </Typography>
              <Box sx={[styles.row]} mt={2}>
                <Typography variant="body1">
                  ¿Te suena muy exagerado?
                </Typography>
                <Typography variant="body1" color={blue} ml={1}>
                  Pues te invitamos a que lo compruebes tu mismo.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Box
                  mr={2}
                  sx={{
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdArrowForward size={45} color={yellow} />
                </Box>
                <RevoButton
                  buttonText={"Inicia tu prueba de 15 días"}
                  backgroundColor={"white"}
                  shadow={blue}
                  color={dark}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={styles.featuresBackground}>
        <Container maxWidth={"xl"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              mt: 1,
              width: "60%",
              [theme.breakpoints.down("sm")]: {
                width: "100",
              },
            }}
          >
            <Box
              mr={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                [theme.breakpoints.down("sm")]: {
                  width: "100",
                },
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  color: blue,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdArrowForward size={45} />
              </Box>
              <Typography variant="h3" sx={{ width: "100%" }}>
                Ingresa hoy
              </Typography>
            </Box>
            <Typography variant="body1" ml={7}>
              Y empieza a transformar tu futuro.
            </Typography>
          </Box>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item md={5} xs={12}>
              {renderIconCards()}
            </Grid>
            <Grid item md={5} xs={12}>
              {renderIconCards2()}
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 6,
            }}
          >
            <RevoButton
              buttonText={"INSCRIBIRME AHORA"}
              backgroundColor={"transparent"}
              shadow={blue}
              color={"white"}
              width={"250px"}
              outlined
              weight={"800"}
              height={"40px"}
              fontSize={15}
              onClick={handleWhatsAppClick}
            />
          </Box>
        </Container>
      </Box>

      <Box sx={styles.bottomBackground}>
        <Container maxWidth={"lg"} sx={styles.section}>
          <Grid container pt={10}>
            <Grid item sm={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h3" textAlign={"center"}>
                  Lo que dicen nuestros
                </Typography>
                <Typography
                  variant="h3"
                  color={yellow}
                  textAlign={"center"}
                  sx={styles.textShadow}
                >
                  estudiantes
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                  Lo mas importante para nosotros es poder ayudarte a cambiar tu
                  realidad financiera, tu mentalidad y tu vida.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container sx={styles.carousel}>
          <LandingCarousel />
        </Container>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 8,
          backgroundImage: `url(${require("../../assets/img/seventh.jpg")})`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "75%",
            height: 8,
            backgroundColor: yellow,
            boxShadow: `-2px -2px 25px 0px ${yellow}90`,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "4px",
          }}
        ></Box>
        <Box
          sx={{
            width: "100%",
            height: 4,
            backgroundColor: "white",
            boxShadow: `-2px -2px 25px 0px ${yellow}90`,
          }}
        ></Box>
      </Box>
      <Box sx={styles.bottom2Background}>
        <Container maxWidth={"lg"} sx={styles.section}>
          <Grid container>
            <Grid item sm={12} sx={styles.responsiveSection}>
              <Box
                sx={{
                  display: "flex",
                  mb: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  [theme.breakpoints.down("sm")]: {
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "0 auto",
                  },
                }}
              >
                <Typography variant="h3">Inicia</Typography>
                <Typography
                  variant="h3"
                  color={yellow}
                  ml={1}
                  sx={styles.textShadow}
                >
                  GRATIS
                </Typography>

                <Typography variant="h3" ml={1}>
                  Ahora
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  [theme.breakpoints.down("sm")]: {
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "0 auto",
                  },
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  Comienza totalmente gratis una
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "800" }} ml={0.5}>
                  prueba de 15 días
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
              sx={[
                {
                  display: "flex",
                  justifyContent: "center",
                  [theme.breakpoints.down("sm")]: {
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em auto",
                  },
                },
              ]}
              mt={4}
            >
              <Divider />
              <RevoButton
                buttonText={"¡QUIERO INICIAR YA!"}
                backgroundColor={"transparent"}
                shadow={blue}
                color={"white"}
                width={"250px"}
                outlined
                weight={"800"}
                height={"40px"}
                fontSize={15}
                icon={<FaRocket />}
                onClick={handleWhatsAppClick}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
