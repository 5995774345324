import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
  IconButton,
  TextField,
} from "@mui/material";
import { blue, dark, styles } from "../styles/styles";
import {
  FaYoutube,
  FaTwitch,
  FaInstagram,
  FaTiktok,
  FaFacebook,
  FaTelegramPlane,
} from "react-icons/fa";
import logo from "../assets/img/Logo.png";

export const RevoFooter = () => {
  const [email, setEmail] = useState("");

  const handleWhatsAppClick = () => {
    const phoneNumber = "5491168502040";

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}
    )}`;

    window.open(whatsappUrl, "_blank");
  };
  const handleEmail = () => {
    const phoneNumber = "83262705";
    const message = `Correo Electrónico: ${email}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      <Box sx={styles.footer}>
        <Container maxWidth="xl">
          <Grid container spacing={2} sx={styles.footerContainer}>
            <Grid item xs={12} sm={6} md={2.5} sx={styles.footerSection}>
              {/* First column content */}
              <Box>
                <Box sx={styles.logoContainer}>
                  <img src={logo} style={styles.logo} />
                </Box>
                <Typography variant="body2">
                  La comunidad #1 de trading, criptomonedas y negocios digitales
                  en Latinoamérica
                </Typography>
              </Box>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item>
                  <Box
                    sx={{
                      bgcolor: blue,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    <IconButton sx={{ color: "dark" }}>
                      <FaYoutube size={18} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      bgcolor: blue,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    <IconButton sx={{ color: "dark" }}>
                      <FaTwitch size={18} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      bgcolor: blue,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    <IconButton sx={{ color: "dark" }}>
                      <FaInstagram size={18} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      bgcolor: blue,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    <IconButton sx={{ color: "dark" }}>
                      <FaTiktok size={18} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      bgcolor: blue,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  >
                    <IconButton sx={{ color: "dark" }}>
                      <FaFacebook size={18} />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              {/* Second column content */}
              <Typography variant="h6">Sobre Nosotros</Typography>

              <List>
                <ListItem>
                  <Link>Comunidad</Link>
                </ListItem>
                <ListItem>
                  <Link>Sobre Nosotros</Link>
                </ListItem>
                <ListItem>
                  <Link>Beneficios</Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              {/* Third column content */}
              <Typography variant="h6">Academia</Typography>

              <List>
                <ListItem>
                  <Link>Academia</Link>
                </ListItem>
                <ListItem>
                  <Link>Cursos</Link>
                </ListItem>
                <ListItem>
                  <Link>Mas Informacion</Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              {/* Fourth column content */}
              <Typography variant="h6">Soporte</Typography>

              <List>
                <ListItem>
                  <Link>Centro de Soporte</Link>
                </ListItem>
                <ListItem>
                  <Link>Contactanos</Link>
                </ListItem>
                <ListItem>
                  <Link href="/terminos">Politica de Privacidad</Link>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              sx={[styles.footerSection, { maxHeight: "150px" }]}
            >
              {/* Fifth column content */}
              <Typography variant="body2">
                Se el primero en conocer las ultimas noticias y tendencias de
                Crypto!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "18ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    sx={{
                      "& input": {
                        padding: "0.6rem", // Adjust the padding value as needed
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>

                <Box
                  sx={{
                    bgcolor: blue,
                    borderRadius: "4px",
                    display: "flex",
                    height: "40px",
                  }}
                >
                  <IconButton
                    sx={[styles.shadowBlue, { color: "dark" }]}
                    onClick={handleEmail}
                  >
                    <FaTelegramPlane size={18} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: "flex",
          background: "#0c0c1b",
          background:
            "  linear-gradient(90deg, rgba(22,22,25,1) 14%, rgba(21,24,40,1) 52%, rgba(20,21,22,1) 87%)",
          height: 50,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>Copyright 2023 Revolution. All rights Reserved.</Typography>
      </Box>
    </>
  );
};
