import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../styles/styles";

export const IconCard = ({ color, item }) => {
  return (
    <Box sx={styles.iconCard}>
      <Box sx={styles.iconImageContainer}> 
        <img src={item.icon}  style={styles.iconImage}/>
      </Box>
      <Typography variant="p" color={color} textAlign={"center"} fontSize={10} mt={0.5}>
        {item.text}
      </Typography>
      <Typography variant="p" color={color} textAlign={"center"} fontSize={10}>
        {item.text2}
      </Typography>
    </Box>
  );
};
