import React from "react";

import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./app/routes/AppRoutes";
import { RevoNavbar } from "./app/shared/RevoNavbar";
import { RevoFooter } from "./app/shared/RevoFooter";

export default function App() {
  return (
    <>
      <CssBaseline />
      <div className="App ">
        <BrowserRouter>
        <RevoNavbar/>
          <AppRoutes />
        <RevoFooter />
        </BrowserRouter>
      </div>
    </>
  );
}
