import theme from '../../revoTheme';

// Extract colors from the theme
const { palette } = theme;
const { primary, secondary } = palette;
const { main: blue, secondary: yellow } = primary;
const { main: dark, secondary: lightDark, terciary: lighterDark } = secondary;

export const carouselStyles = {

    carouselCard: {
        display:'flex',
        flexDirection:'column',
        backgroundColor: `${lighterDark}85`,
        height: "200px",
        maxWidth: "330px",
        minWidth: "330px",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      margin:'0 2em',
      borderRadius:'18px',
  
      },
    carouselImageContainer: {
        display: "flex",
        justifyContent: "center",
        width: "48px",
        height: "auto",
    
    
      },
      carouselImage : {
        width: "100%",
        height: "auto",
        textAlign:'center',
        margin:'0 auto',
    
      },
  center: {
    display: 'flex',
    justifyContent: 'center',
    height: 'auto',
  },


  navigationWrapper: {
    position: 'relative',
  },

  dots: {
    display: 'flex',
    padding: '10px 0',
    justifyContent: 'center',
  },

  dot: {
    border: 'none',
    width: '10px',
    height: '10px',
    background: '#c5c5c5',
    borderRadius: '50%',
    margin: '0 5px',
    padding: '5px',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },

    '&.active': {
      background: '#000',
    },
  },

  arrow: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    background: 'rgb(255, 255, 255)',
    borderRadius: '50%',
    padding: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    fill: 'white',
    cursor: 'pointer',
  },

  arrowLeft: {
    left: '10px',

    '@media (max-width: 768px)': {
      left: '10px',
    },
  },

  arrowRight: {
    left: 'auto',
    right: '10px',

    '@media (max-width: 768px)': {
      right: '10px',
    },
  },

  arrowDisabled: {
    fill: 'disabled',
  },
};
