import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../../styles/scss/swiper.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import { Avatar, Box, Typography } from "@mui/material";
import { carouselStyles } from "../../styles/carouselStyles";
import { carouselData } from "../utils/CardData"; // Import the carouselData
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaStar } from "react-icons/fa";

export const LandingCarousel = () => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div className="swiper-button image-swiper-button-next">
        <IoIosArrowForward />
      </div>
      <div className="swiper-button image-swiper-button-prev">
        <IoIosArrowBack />
      </div>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesPerView={3}
        navigation={{
          nextEl: ".image-swiper-button-next",
          prevEl: ".image-swiper-button-prev",
          disabledClass: "swiper-button-disabled",
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {/* Map through carouselData and render SwiperSlides */}
        {carouselData.map((item, index) => (
          <SwiperSlide key={index}>
            <Box sx={carouselStyles.carouselCard}>
              <Box sx={{ pt:2,pb:2,pl: 3, pr: 3 ,}}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: "auto",
                  }}
                >
                  <Avatar alt="Remy Sharp" src="https://picsum.photos/200" />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{fontSize:10,ml:1}}>{item.name}</Typography>
                    <Typography variant="body2" sx={{fontSize:10,ml:1}}>
                      {item.jobDescription}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaStar size={20} color="gold" />
                      <Typography variant="body2" ml={1}>
                        {item.starRating}
                      </Typography>
                    </div>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="body1" mt={2} textAlign={'left'}>
                    {item.testimony}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
