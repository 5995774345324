import { db } from "../../firebase/firebase-config";

export const registerRequest = async (data, currentType) => {
  try {
    // Send request to external endpoint
    console.log(data);
    const res = await fetch(
      "https://us-central1-revo-47fdb.cloudfunctions.net/mailer/requestReceived",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          name: data.name,
        }),
      }
    );
    console.log(res);
    // If the request to the external endpoint is successful
    if (res.ok) {
      // Increment the count field based on currentType
      const requestRef = db.collection("dbRefs").doc("requests");
      const doc = await requestRef.get();

      if (!doc.exists) {
        throw new Error("Document not found");
      }

      const currentCount = doc.data()[currentType] || 0;
      const newCount = currentCount + 1;

      const updateData = {};
      updateData[currentType] = newCount;

      await requestRef.update(updateData);

      // Add data to contacts collection
      const updatedData = {
        ...data,
        requestEmailSent: true,
      };
      await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .add(updatedData);
    }
  } catch (error) {
    console.error(error);
    // Handle the error here or rethrow it if needed
    throw error;
  }
};

export const checkIfUserExists = async (brokerId) => {
  const querySnapshot = await db
    .collection("users")
    .where("brokerId", "==", brokerId)
    .get();
  return !querySnapshot.empty;
};
export const deleteAccount = async (data) => {
  try {
    // Assuming 'bcollection' is a typo and you meant 'firestore'
    await db
      .collection("contacts")
      .doc("deleteRequests")
      .collection("requests")
      .add(data);
  } catch (error) {
    console.error(error);
    // Handle the error here or rethrow it if needed
    throw error;
  }
};
