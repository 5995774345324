import theme from "../../revoTheme";

export const navbarStyles = {
  appBar: {
    background: "transparent",
    boxShadow: "none",
    height: "175px",
    pt: 4,
  },
  link: {
    color: "white",
    textDecoration: "none",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
    "&:active": {
      color: theme.palette.primary.main,
    },
  },
  logoContainer: {
    width: "250px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "250px",
      height: "auto",
  },
  },
  logo: {
    width: "100%",
    height: "auto",
  },
  logoContainer2: {
    width: "50px",
    height: "auto",

  },
  logo2: {
    width: "100%",
    height: "auto",
  },

  navbarLinks: {
    display: "flex",
    justifyContent: "space-between",
  },
};
