import theme from "../../revoTheme";
import cardImage from "../assets/img/card.png"; // Import the image here

export const blue = theme.palette.primary.main;
export const yellow = theme.palette.primary.secondary;
export const dark = theme.palette.secondary.main;
export const lightDark = theme.palette.secondary.secondary;
export const lighterDark = theme.palette.secondary.terciary;

export const styles = {
  center: {
    display: "flex",
    justifyContent: "center",

    height: "auto",
  },

  boldText: {
    fontWeight: "bold",
  },
  boldTextTerms: {
    fontWeight: "800 !important",
    fontSize: "1.3rem !important",

    color: theme.palette.primary.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem !important",
    },
  },
  boldTextLight: {
    fontWeight: "800 !important",
    fontSize: "1.3rem !important",
    color: "white",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem !important",
    },
  },

  textShadow: {
    textShadow:
      "2px 2px 20px rgba(0, 0, 0, 0.25), -2px -2px 18px rgba(255, 187, 0.25,0.4)",
  },
  shadowBlue: {
    boxShadow: `-2px -2px 25px 0px ${blue}90`,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      mt: 2,
    },
  },
  contactHeader: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: theme.palette.primary.main,
    // backgroundImage: `url(${require("../assets/img/blackjack.jpg")})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    minHeight: "15vh",
    zIndex: 1000,
    top: 0,
    right: 0,
  },
  headerBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../assets/img/headerBackground.png")})`,
    height: "75vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("lg")]: {
      height: "90vh",
    },
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
  },

  joinBackground: {
    display: "flex",
    justifyContent: "center",
    alignitems: "center",
    backgroundImage: `url(${require("../assets/img/second.jpg")})`,
    height: "85vh",
    backgroundSize: "cover",
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  knowMoreBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../assets/img/third.jpg")})`,
    height: "80vh",
    backgroundSize: "cover",

    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
  },

  bePartBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../assets/img/fourth.jpg")})`,
    height: "85vh",
    backgroundSize: "cover",
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
  },

  featuresBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../assets/img/fifth.jpg")})`,
    height: "60vh",
    backgroundSize: "cover",
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
  },

  bottomBackground: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${require("../assets/img/sixth.jpg")})`,
    height: "75vh",
    backgroundSize: "cover",
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "4em 0 4em 0",
    },
  },
  bottom2Background: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../assets/img/seventh.jpg")})`,
    height: "40vh",
    backgroundSize: "cover",
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "4em 0 4em 0",
    },
  },

  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerSection: {
    [theme.breakpoints.down("md")]: {
      mb: 20,
    },
  },

  responsiveSection: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 auto",
    },
  },

  //IMAGES

  coinImgContainer: {
    display: "flex",
    justifyContent: "center",
    width: "475px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "275px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
  },

  coinImg: {
    width: "100%",
    height: "auto",
  },

  mapImgContainer: {
    width: "600px",
    height: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "275px",
      margin: "0 auto",
    },
  },
  mapImg: {
    width: "100%",
    height: "auto",
  },

  astronautImgContainer: {
    width: "500px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "375px",
    },
  },

  astronautImg: {
    width: "100%",
    height: "auto",
  },

  iconImageContainer: {
    display: "flex",
    justifyContent: "center",
    width: "48px",
    height: "auto",
  },
  iconImage: {
    width: "100%",
    height: "auto",
    textAlign: "center",
    margin: "0 auto",
  },

  iconImageContainer2: {
    display: "flex",
    justifyContent: "center",
    width: "48px",
    height: "auto",
  },
  iconImage2: {
    width: "100%",
    height: "auto",
    textAlign: "center",
    margin: "0 auto",
  },

  //// CARDS

  infoCard: {
    background: `linear-gradient(to right bottom, ${dark}, ${lighterDark})`,
    borderRadius: "4px",
    height: "125px",
    width: "125px",
    justifyContent: "center",
    alignItems: "center",

    p: 2,
  },
  infoCardContainer: {
    position: "relative",
    width: "75%",
    mt: 2,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "1em auto",
      paddingRight: "1em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },

  infoCardActive: {},

  iconCard: {
    display: "flex",
    flexDirection: "column",

    backgroundImage: `url(${cardImage})`,
    backgroundColor: lighterDark,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "125px",
    width: "125px",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    borderRadius: "4px",
    p: 0.2,
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      width: "100px",
    },
  },
  iconCard2: {
    display: "flex",
    flexDirection: "column",

    backgroundColor: `${lighterDark}75`,
    height: "125px",
    width: "125px",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    p: 0.2,
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      width: "100px",
    },
  },
  carouselCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: `${lighterDark}75`,
    height: "125px",
    width: "200px",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    p: 0.2,
  },

  footer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
  },
  footerContainer: {
    justifyContent: "center",

    minHeight: "28vh",
  },

  carousel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "45vh",
    width: "100%",
  },

  footerSection: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "225px",
      margin: "2em auto",
    },
  },
  logoContainer: {
    width: "200px",
    height: "auto",
  },
  logo: {
    width: "100%",
    height: "auto",
  },

  titleGridContainer: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "50px",
    marginRight: "auto",
    marginLeft: "auto",

    // maxWidth: '1330px',
  },
  titleCard: {
    // width:300,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    padding: "0.3rem 4rem 0.3rem 0.9rem",
    borderRadius: "4px",
    // clipPath: "polygon(0 0, 100% 0, 90% 96%, 0 100%)",
  },
};
