import React from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
  useTheme,
  Container,
  Box,
  Grid,
  Typography,
  FormHelperText,
} from "@mui/material";
import jwtDecode from "jwt-decode";

import { registerForm } from "../../utils/formData";
import { RevoButton } from "../../components/general/RevoButton";
import { blue, yellow } from "../../styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { checkIfUserExists, registerRequest } from "../../utils/firebaseUtils";
import { useNavigate } from "react-router-dom";
const MySwal = withReactContent(Swal);

export const Register = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    name: yup.string().required("Campo obligatorio"),
    lastname: yup.string().required("Campo obligatorio"),
    email: yup
      .string()
      .email("Introduce una dirección de correo electrónico válida")
      .required("Campo obligatorio"),
    confirmEmail: yup
      .string()
      .oneOf(
        [yup.ref("email"), null],
        "Los correos electrónicos deben coincidir"
      ) // Ensure emails match
      .required("Campo obligatorio"),
    selectedAnswer: yup.number().required("Campo obligatorio"),

    agreeToTerms: yup
      .boolean()
      .oneOf([true], "Debes aceptar los términos y condiciones"),

    discordId: yup.string().required("Campo obligatorio"),
    brokerId: yup
      .string()
      .required("Campo obligatorio")
      .test(
        "startsWith18",
        "El ID de broker es invalido",
        (value) => value && value.startsWith("18")
      ),
    phone: yup.string().required("Campo obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",

      agreeToTerms: false,
      selectedAnswer: 0,
      discordId: "",
      brokerId: "",
      phone: "",
      email: "",
      confirmEmail: "",
      referrer: "",
    },
    enableReinitialize: false,
    validateOnMount: false,

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values.selectedAnswer);
      const confirmResult = await Swal.fire({
        title: "¿Deseas enviar tu solicitud?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, enviar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Enviando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const dataToSend = {
          ...values,
          cdate: new Date(),
          status: "pending",
          reason:
            values.selectedAnswer === 1
              ? "Es mi primer ingreso a Revolution"
              : " Ya tengo acceso a la app, solo quiero acceso al curso avanzado",
        };
        try {
          let currentType = "pendingFirst"; // Default type
          if (values.selectedAnswer === 1) {
            currentType = "pendingFirst";
          } else {
            currentType = "pendingUpgrade";
          }

          await registerRequest(dataToSend, currentType);
          // console.log(values);
          formik.resetForm();
          loadingSwal.close();
          MySwal.fire({
            title: `Solicitud enviada`,
            icon: "success",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          });
        } catch (error) {
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
          console.log(error);
        }
      }
    },
  });

  const preventRightClick = (event) => {
    // Prevent the default context menu (right-click menu) from appearing
    event.preventDefault();

    // Optionally, you can provide feedback to the user that right-clicking is not allowed
  };
  const navigateTo = (link) => {
    navigate(link);
  };
  const handleCopy = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {/* <Header theme={theme} /> */}
      <Container maxWidth={"lg"}>
        <Grid container sx={{ minHeight: "75vh" }} pt={22} pb={10}>
          <Grid
            container
            item
            justifyContent={"center"}
            alignItems={"center"}
            mb={8}
          >
            <Grid item>
              <Typography variant="h5" sx={{ fontSize: 18, color: "white" }}>
                FORMULARIO DE INGRESO A REVOLUTION
              </Typography>
            </Grid>
          </Grid>
          <Box
            component={"form"}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              mb={4}
            >
              <Grid item md={10} mb={1} xs={11} sm={11}>
                <FormLabel>
                  <Typography
                    variant="h7"
                    sx={{ fontSize: 18, color: "white" }}
                  >
                    {registerForm[0].question}
                  </Typography>
                </FormLabel>
                <Grid item md={12} mb={1}>
                  <Box mt={2}>
                    <Typography
                      sx={{
                        fontWeight: "400 !important",
                        fontSize: "12px !important",
                        textTransform: "capitalize",
                        fontStyle: "italic",
                        pl: 2,
                      }}
                    >
                      RESPONDE TILDANDO SOLO LA OPCION QUE CORRESPONDE:
                    </Typography>
                  </Box>
                </Grid>
                <FormControl component="fieldset" sx={{ mt: 2, width: "100%" }}>
                  <RadioGroup
                    name="selectedAnswer"
                    value={formik.values.selectedAnswer.toString()} // Convert to string
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue("selectedAnswer", +e.target.value)
                    } // Convert to number
                  >
                    {registerForm[0].answers.map((answer) => (
                      <FormControlLabel
                        sx={{
                          borderColor:
                            answer.id.toString() ===
                            formik.values.selectedAnswer
                              ? blue
                              : "#A1A1A1",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "18px",
                          padding: 0.5,
                          margin: 1,
                          marginLeft: 1,
                          "& .MuiRadio-colorPrimary.Mui-checked": {
                            color: yellow,
                          },
                        }}
                        key={answer.id}
                        value={answer.id.toString()} // Convert to string
                        control={<Radio />}
                        label={answer.answer}
                      />
                    ))}
                  </RadioGroup>
                  {formik.touched.selectedAnswer &&
                    formik.errors.selectedAnswer && (
                      <FormHelperText error>
                        {formik.errors.selectedAnswer}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            </Grid>

            <>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                xs={12}
                sm={12}
                columnGap={1}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        1.
                      </span>{" "}
                      NOMBRE Y APELLIDO
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={5.6} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre"
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Box>
                </Grid>
                <Grid item md={5.6} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="lastname"
                      name="lastname"
                      label="Apellido"
                      value={formik.values.lastname}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box mb={2}>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        2.
                      </span>{" "}
                      ID de tu cuenta real del broker
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "400 !important",
                        fontSize: "12px !important",
                        pl: 2,
                        pr: 2,
                      }}
                    >
                      ingresa el ID de la cuenta real del broker que estas
                      utilizando con nosotros
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="brokerId"
                      name="brokerId"
                      label="ID de tu cuenta real del broker"
                      value={formik.values.brokerId}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.brokerId &&
                        Boolean(formik.errors.brokerId)
                      }
                      helperText={
                        formik.touched.brokerId ? formik.errors.brokerId : ""
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box mb={2}>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        3.
                      </span>{" "}
                      TU USUARIO DE DISCORD
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "400 !important",
                        fontSize: "12px !important",
                        pl: 2,
                        pr: 2,
                      }}
                    >
                      ingresa tu usuario de Discord.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="discordId"
                      name="discordId"
                      label="TU USUARIO DE DISCORD"
                      value={formik.values.discordId}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.discordId &&
                        Boolean(formik.errors.discordId)
                      }
                      helperText={
                        formik.touched.discordId && formik.errors.discordId
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box mb={2}>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        4.
                      </span>{" "}
                      CORREO ELECTRÓNICO
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "400 !important",
                        fontSize: "12px !important",
                        pl: 2,
                        pr: 2,
                      }}
                    >
                      Escribí el mail que utilicés frecuentemente, allí
                      recibiras el usuario y clave para entrar a la app,
                      corroborá el mail, los puntos y el @.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="email"
                      name="email"
                      label="CORREO ELECTRÓNICO"
                      value={formik.values.email}
                      onCut={handleCopy}
                      onCopy={preventRightClick} // Prevent right-clicking
                      onContextMenu={preventRightClick} // Prevent right-clicking
                      onPaste={handleCopy}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box mb={2}>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        5.
                      </span>{" "}
                      CONFIRMAR CORREO ELECTRÓNICO
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="confirmEmail"
                      name="confirmEmail"
                      label="Confirmar correo electrónico"
                      value={formik.values.confirmEmail}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmEmail &&
                        Boolean(formik.errors.confirmEmail)
                      }
                      helperText={
                        formik.touched.confirmEmail &&
                        formik.errors.confirmEmail
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box mb={2}>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        6.
                      </span>{" "}
                      NUMERO DE TELÉFONO
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="phone"
                      name="phone"
                      label="NUMERO DE TELÉFONO"
                      value={formik.values.phone}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box mb={2}>
                    <Typography>
                      <span style={{ color: theme.palette.primary.secondary }}>
                        7.
                      </span>{" "}
                      PERSONA CON LA QUE INICIASTE
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "400 !important",
                        fontSize: "12px !important",
                        pl: 2,
                        pr: 2,
                      }}
                    >
                      Nombre y apellido de la persona con la que comenzaste en
                      Revolution
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} mb={1} xs={11} sm={11}>
                  <Box style={{ height: "80px" }}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="referrer"
                      name="referrer"
                      label="PERSONA CON LA QUE INICIASTE"
                      value={formik.values.referrer}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.referrer &&
                        Boolean(formik.errors.referrer)
                      }
                      helperText={
                        formik.touched.referrer && formik.errors.referrer
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={{
                  md: "space-between",
                  xs: "center",
                  sm: "center",
                  lg: "space-between",
                }}
                alignItems={"center"}
                md={10}
                my={2}
              >
                <FormControl
                  component="fieldset"
                  sx={{
                    flexDirection: "row",
                    marginBottom: "15px",
                    mr: 1,
                  }}
                >
                  <FormControlLabel
                    sx={{
                      marginLeft: "0px !important",
                      flexDirection: "row",
                    }}
                    value="agreeToTerms"
                    label={
                      <Typography>
                        Acepto los{" "}
                        <a
                          href="https://revolutionarg.com/terminos"
                          target="_blank"
                          style={{ color: yellow }}
                        >
                          Términos y Condiciones
                        </a>
                      </Typography>
                    }
                    labelPlacement="start"
                    control={
                      <Checkbox
                        name="agreeToTerms"
                        checked={formik.values.agreeToTerms}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                item
                justifyContent={"center"}
                alignItems={"center"}
                my={4}
              >
                <Grid
                  container
                  item
                  md={10}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <RevoButton
                    buttonText={"Enviar"}
                    backgroundColor={blue}
                    color={"white"}
                    disabled={!formik.isValid}
                    shadow={blue}
                    onClick={formik.handleSubmit}
                  />
                </Grid>
              </Grid>{" "}
            </>
          </Box>
        </Grid>
      </Container>
    </>
  );
};
