import dollar from "../../assets/img/dollar.png";
import chat from "../../assets/img/chat.png";
import support from "../../assets/img/support.png";
import onlinelearning from "../../assets/img/onlinelearning.png";
import levels from "../../assets/img/levels.png";
import videos from "../../assets/img/videos.png";

export const infoCardData = [
  {
    id: "0",
    text: "Estudiantes en todo el mundo",
    bigText: "3k+",
  },
  {
    id: "1",
    text: "En ganancias en los últimos 4 años",
    bigText: "10M+",
  },
  {
    id: "2",
    text: "Ciudades en todo el mundo",
    bigText: "30+",
  },
];

export const iconCardData = [
  {
    id: "0",
    text: "Invierte en tu",
    text2: "FUTURO",
    icon: dollar,
  },
  {
    id: "1",
    text: "Chat con otros",
    text2: "ALUMNOS",
    icon: chat,
  },
  {
    id: "2",
    text: "Consultas a",
    text2: "ESPECIALISTAS",
    icon: support,
  },
];

export const iconCardData2 = [
  {
    id: "0",
    text: "3 NIVELES",
    icon: levels,
  },
  {
    id: "1",
    text: "15 VIDEOS",
    icon: videos,
  },
  {
    id: "2",
    text: "+50  HORAS",
    icon: onlinelearning,
  },
];


export const carouselData = [
  {
    id: "0",
    text: "3 NIVELES",
    img: levels,
    avatarImg: "https://placekitten.com/80/80", // Sample avatar image URL
    name: "John Doe",
    jobDescription: "Software Engineer",
    starRating: 4.5,
    testimony: "The course was amazing! It helped me improve my skills.",
  },
  {
    id: "1",
    text: "15 VIDEOS",
    img: videos,
    avatarImg: "https://placekitten.com/80/80", // Sample avatar image URL
    name: "Jane Smith",
    jobDescription: "Graphic Designer",
    starRating: 4.8,
    testimony: "I loved the video content. Very informative and creative.",
  },
  {
    id: "2",
    text: "+50  HORAS",
    img: onlinelearning,
    avatarImg: "https://placekitten.com/80/80", // Sample avatar image URL
    name: "Michael Johnson",
    jobDescription: "Marketing Specialist",
    starRating: 4.2,
    testimony: "The course provided valuable insights. Highly recommended.",
  },
];
